import { PaginationConfig } from 'antd/lib/pagination';
import moment from 'moment';
import { AppTypes, RegisterStatus } from 'types';

const initPagination: PaginationConfig = {
  current: 1,
  pageSize: 10,
  total: 0,
  // showTotal: (total, range) => (
  //   <Button type='dashed' className='color-darkcyan' title='Total'>
  //     •《 {total} 》•
  //   </Button>
  // ),
  showSizeChanger: true,
  pageSizeOptions: ['5', '10', '20', '50'],
};

const formatDateTable = (day: string) => {
  return moment(day).format('DD/MM/YYYY HH:mm');
};

const RegisterStatusColor = {
  [RegisterStatus.Pending]: 'default',
  [RegisterStatus.Approved]: 'success',
  [RegisterStatus.Rejected]: 'red',
};

export const getRegisterStatusColor = (status: RegisterStatus) => {
  return RegisterStatusColor[status];
};

export const AppTypeName = {
  [AppTypes.WEB_DESKTOP]: 'Web Desktop',
  [AppTypes.WEB_MOBILE]: 'Web Mobile',
  [AppTypes.ANDROID]: 'Android',
  [AppTypes.IOS]: 'iOS',
};

export const getAppTypeName = (name: AppTypes) => {
  return AppTypeName[name];
};

export const AppTypeColor = {
  [AppTypes.WEB_DESKTOP]: 'magenta',
  [AppTypes.WEB_MOBILE]: 'orange',
  [AppTypes.ANDROID]: 'green',
  [AppTypes.IOS]: 'geekblue',
};

export const getAppTypeColor = (name: AppTypes) => {
  return AppTypeColor[name];
};

const validateEmail = (text: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(text);
};

const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const encodeQueryData = (data: any): string => {
  const ret = [];
  for (let d in data)
    ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
  return ret.join('&');
};

export default {
  initPagination,
  formatDateTable,
  RegisterStatus,
  getRegisterStatusColor,
  AppTypes,
  getAppTypeName,
  getAppTypeColor,
  validateEmail,
  numberWithCommas,
  encodeQueryData,
};
