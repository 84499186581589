import LoadingPage from 'components/LoadingPage';
import { authConstants } from 'constants/index';
import { useEffect } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import React, { useGlobal, useState } from 'reactn';
import { userServices } from 'services';

interface PropsT extends RouteProps {
  component: any;
  name?: string;
}

export default (props: PropsT) => {
  const { component: Component, ...rest } = props;
  const [loading, setLoading] = useState(true);
  const [, setCurrentUser] = useGlobal<any>(authConstants.KEY_CURRENT_USER);

  useEffect(() => {
    if (!userServices.isLoggedIn()) {
      userServices.login();
    } else {
      userServices.getFullUserInfo().then((data: any) => {
        setLoading(false);
        setCurrentUser(data);
      });
    }
  }, [setCurrentUser]);

  if (loading) return <LoadingPage />;
  return (
    <Route {...rest} render={(routeProps) => <Component {...routeProps} />} />
  );
};
