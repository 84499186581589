import axios from 'axios/index';
import { REACT_APP_BASE_API_URL } from 'env';
import { requestHelper } from 'helpers';
import _ from 'lodash';
import { userServices } from 'services';
import { InvalidDetailFilterT, InvalidStatisticFilterT } from 'types';

const transformRequestOptions = (params: any) => {
  let options = '';
  for (const key in params) {
    if (typeof params[key] !== 'object' && params[key]) {
      options += `${key}=${params[key]}&`;
    } else if (
      typeof params[key] === 'object' &&
      params[key] &&
      params[key].length
    ) {
      params[key].forEach((el: any) => {
        options += `${key}=${el}&`;
      });
    }
  }
  return options ? options.slice(0, -1) : options;
};

export const BASE_API_URL: string = `${REACT_APP_BASE_API_URL}/api`;

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    requestHelper.handleRequestError(error);
    return Promise.reject(error);
  },
);

const getAuthorization = () => `Bearer ${userServices.getAccessToken()}`;

const userClient = axios.create({
  baseURL: BASE_API_URL,
  withCredentials: false,
});

userClient.interceptors.request.use(
  (config) => {
    config.headers.Authorization = getAuthorization();
    // TODO: remove this test
    config.headers['X-User-ID'] = '1';
    return config;
  },
  (error) => Promise.reject(error),
);

userClient.interceptors.response.use(
  (response) => response,
  (error) => {
    requestHelper.handleRequestError(error);
    return Promise.reject(error);
  },
);

const getGroups = async () => {
  const res = await userClient.get('/groups');
  return _.get(res, 'data.groups', []);
};

const createGroup = async (body: any) => {
  const res = await userClient.post(`/groups`, body);
  return _.get(res, 'data.groups', {});
};

const updateGroup = async (id: number | string, body: any) => {
  const res = await userClient.put(`/groups/${id}`, body);
  return _.get(res, 'data.groups', {});
};

const getAllApplications = async () => {
  const res = await userClient.get('/applications');
  return _.get(res, 'data.applications', []);
};

const getAllRegisters = async (filter: any) => {
  const res = await userClient.get('/registers/all', { params: filter });
  return _.get(res, 'data.registers', []);
};

const getOwnerRegisters = async () => {
  const res = await userClient.get('/registers');
  return _.get(res, 'data.registers', []);
};

const createRegister = async (body: any) => {
  const res = await userClient.post('/registers', body);
  return _.get(res, 'data.registers', {});
};

const updateRequest = async (id: string | number, body: any) => {
  const res = await userClient.put(`/registers/${id}`, body);
  return _.get(res, 'data.registers', {});
};

const approveRegister = async (id: string | number) => {
  const res = await userClient.put(`/registers/${id}/approve`);
  return _.get(res, 'data.registers', {});
};

const syncRegister = async (id: string | number) => {
  const res = await userClient.put(`/registers/${id}/create-elastic-stuffs`);
  return _.get(res, 'data.registers', {});
};

const syncRegisterWithoutEmail = async (id: string | number) => {
  const res = await userClient.put(
    `/registers/${id}/create-elastic-stuffs?isSendEmail=false`,
  );
  return _.get(res, 'data.registers', {});
};

const syncGroup = async (id: number) => {
  const res = await userClient.post(`/groups/${id}/tenant`);
  return _.get(res, 'data.registers', {});
};

const appendEmailsByGroup = async (dto: any) => {
  const res = await userClient.post(`/groups/register-users`, dto);
  return _.get(res, 'data', {});
};

const rejectRegister = async (id: string | number) => {
  const res = await userClient.put(`/registers/${id}/reject`);
  return _.get(res, 'data.registers', {});
};

const getStatisticInvalid = async (filter?: InvalidStatisticFilterT) => {
  if (!filter || !filter.appid) return {};
  const res = await userClient.get(`/invalid/statistics`, {
    params: filter,
    paramsSerializer: transformRequestOptions,
  });
  return _.get(res, 'data.statistics', {});
};

const getInvalidSummaryEvents = async (filter?: InvalidStatisticFilterT) => {
  const res = await userClient.get('/v1/invalid', { params: filter });
  return _.get(res, 'data', []);
};

const getInvalidDetailEvents = async (filter?: InvalidDetailFilterT) => {
  const res = await userClient.get(`/v1/invalid/details`, {
    params: filter,
  });
  return _.get(res, 'data', []);
};

const syncRegisterAll = async (ids: string[], cS?: number) => {
  const chunkSize = cS || 1;
  const chunks = _.chunk(ids, chunkSize);

  for (const chunk of chunks) {
    try {
      await Promise.all(
        _.map(chunk, (id) =>
          userClient.put(
            `/registers/${id}/create-elastic-stuffs?isSendEmail=false`,
          ),
        ),
      );
    } catch (error) {
      console.error(error);
    }
  }
};

export default {
  userClient,
  getGroups,
  getOwnerRegisters,
  getAllRegisters,
  createRegister,
  createGroup,
  updateGroup,
  approveRegister,
  rejectRegister,
  updateRequest,
  syncRegister,
  syncGroup,
  getAllApplications,
  getStatisticInvalid,
  getInvalidSummaryEvents,
  appendEmailsByGroup,
  getInvalidDetailEvents,
  syncRegisterWithoutEmail,
  syncRegisterAll,
};
