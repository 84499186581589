export type NotificationTypeT = 'danger' | 'warning' | 'success' | 'info';
export type NotificationT = {
  id: string;
  title: string;
  message: string;
  type: NotificationTypeT;
  createdAt: string;
  seen: boolean;
};

export enum AppTypes {
  WEB_DESKTOP = 'web_desktop',
  WEB_MOBILE = 'web_mobile',
  ANDROID = 'android',
  IOS = 'ios',
}

export enum RegisterStatus {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
}

export type RequestT = {
  uuid: number;
  terminalName: string;
  groupId: number;
  appTypes: AppTypes[];
  status: RegisterStatus;
  devEmails: string[];
  prodEmails: string[];
};

export type GroupT = {
  groupId: number;
  groupName: string;
  description?: string;
};

export type AppendUserByGroupT = {
  groupId: number;
  devEmails: string[];
  prodEmails: string[];
};

export type ApplicationT = {
  appId: string;
  appName: string;
  groupId: string;
};

export type VersionT = {
  version: string;
};

export type InvalidStatisticFilterT = {
  appid?: string;
  version?: string;
  range?: string;
};

export type InvalidDetailFilterT = {
  appid?: string | undefined;
  version?: string | undefined;
  reason?: string | undefined;
  eventName?: string | undefined;
  screenName?: string | undefined;
  limit?: number | undefined;
  page?: number | undefined;
};

export type BannerT = {
  id: number;
  appId?: string | undefined;
  isActive: boolean;
  startAt: string;
  endAt: string;
  imageUrl: string;
};

export type BatchLoadingT = {
  [key: string]: boolean;
};
