import 'antd/dist/antd.less';
import ConfigProvider from 'antd/lib/config-provider';
import { localizationHelper } from 'helpers';
import { languages } from 'localization';
import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import App from './App';
import i18n from './i18n';
import * as serviceWorker from './serviceWorker';
import './themes/index.less';
import * as moment from 'moment';
import 'moment/locale/vi';
//@ts-ignore
import TekoID from 'teko-oauth2';

moment.locale('vi');

// @ts-ignore
TekoID.init({
  clientId: (window as any).__VARIABLES__.REACT_APP_CLIENT_ID,
  scopes: ['profile', 'openid', 'read:permissions', 'tracking-register'],
  oauthDomain: (window as any).__VARIABLES__.REACT_APP_OAUTH_DOMAIN,
}).then(() => {
  ReactDOM.render(
    <I18nextProvider i18n={i18n}>
      <ConfigProvider
        locale={languages[localizationHelper.getCurrentLanguage()].antLocale}
      >
        <App />
      </ConfigProvider>
    </I18nextProvider>,
    document.getElementById('root'),
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
