import { browserHistory } from 'helpers';
import TekoID from 'teko-oauth2';

const logout = () => {
  // @ts-ignore
  TekoID.user.logout();
};

const isLoggedIn = () => {
  // @ts-ignore
  return TekoID.user.isLoggedIn();
};

const denyAccess = () => {
  browserHistory.push('/403');
};

// @ts-ignore
const getAccessToken = () => (TekoID.user ? TekoID.user.getAccessToken() : '');

const getCurrentUserInfo = () => {
  // @ts-ignore
  return TekoID.user.getUserInfo();
};

const getFullUserInfo = () => {
  // @ts-ignore
  return TekoID.user.getFullUserInfo();
};

const getCurrentUserId = (): string => {
  // @ts-ignore
  return TekoID.user.getUserInfo()?.sub;
};

const login = () => {
  // @ts-ignore
  TekoID.user.login();
};
export default {
  logout,
  denyAccess,
  getAccessToken,
  getCurrentUserInfo,
  getCurrentUserId,
  isLoggedIn,
  getFullUserInfo,
  login,
};
