import LoadingPage from 'components/LoadingPage';
import 'configs';
import { browserHistory } from 'helpers';
import React from 'react';
import Loadable from 'react-loadable';
import { Route, Router, Switch } from 'react-router-dom';
import './app.less';
import PrivateRoute from './components/PrivateRoute';
import ApplicationCreate from 'screens/ApplicationCreate';
import ReactTracker from 'react-tracker-teko';
import { userServices } from 'services';
import { REACT_APP_TRACKING_APPLICATION } from 'env';

const reactTracker = new ReactTracker({
  // Configure your tracker server and site by providing
  host: 'https://tracking.tekoapis.com',
  urlServeJsFile:
    'https://cdn.tekoapis.com/tracker/dist/v2/tracker.full.min.js',
  appId: REACT_APP_TRACKING_APPLICATION,
});

const loading = () => <LoadingPage />;

// Containers
const DefaultLayout = Loadable({
  loader: () => import('./components/DefaultLayout'),
  loading,
});

const Page404 = Loadable({
  loader: () => import('./screens/Page404'),
  loading,
});

const Page403 = Loadable({
  loader: () => import('./screens/Page403'),
  loading,
});

const Page500 = Loadable({
  loader: () => import('./screens/Page500'),
  loading,
});

function App() {
  const userId = userServices.getCurrentUserId();
  if (userId) {
    (window as any).track('setUserId', userId);
  }
  (window as any).track('enableTrackVisibleContentImpressions', true);

  return (
    <Router history={reactTracker.connectToHistory(browserHistory)}>
      <Switch>
        <Route exact path="/404" name="Page 404" component={Page404} />
        <Route exact path="/403" name="Page 403" component={Page403} />
        <Route exact path="/500" name="Page 500" component={Page500} />
        <PrivateRoute path="/" name="Home" component={DefaultLayout} />
      </Switch>
    </Router>
  );
}

export default App;
